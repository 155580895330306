.error {
  font-family: MontserratRegular;
  color: #eb5e55;
  font-size: 10px;
  /* display: block;
  padding-left: 1rem;
  padding-top: 1px; */
}

.input.input_error {
  border: 1px solid #eb5e55;
  color: #eb5e55;
}

.radioButton.radio_error > div[role="radio"] {
  border: 2px solid #eb5e55;
  color: #eb5e55;
}

.input {
  background-color: white;
  display: flex;
  flex-direction: column;
  /* border: 1px solid #ede8e9; */
  border-radius: 8px;
  padding: 6px 18px;
  width: 100%;
}

.input input {
  width: 100%;
  border: 0px solid !important;
  padding: 0px 8px 0px 0px;
}

.input input,
.input label {
  font-family: MontserratRegular;
  font-style: normal;
  line-height: 21px;
  letter-spacing: -0.1px;
}

.input input {
  font-weight: bold;
  font-size: 16px;
  color: #3a3335;
  background: #fff;
  height: 24px;
}

.input input[placeholder]::-webkit-input-placeholder {
  font-weight: normal;
  font-size: 12px;
  color: #aaa8a8;
}

.input textarea{
  padding:0px;
}

.input textarea[placeholder]::-webkit-input-placeholder{
  font-weight: normal;
  font-size: 14px;
  color: #aaa8a8;
}

.input input[placeholder]:focus::-webkit-input-placeholder,
.input textarea[placeholder]:focus::-webkit-input-placeholder {
  color: transparent;
}

.inputWrapper > input:enabled:focus, .inputPass > div > input:enabled:focus{
  box-shadow: none !important;
  border-color: #495057 !important;
}

.p-inputtext:enabled:hover {
  border-color: #495057 !important;
}

.inputWrapper span[class="p-calendar"] {
  width: 100% !important;
}

.inputWrapper textarea{
  border: none !important;
}

.input label {
  font-weight: normal;
  font-size: 12px;
  color: #908e8e;
}

.inputPass > div,
.inputWrapper > input {
  width: calc(100% - 20px);
  margin-right: 2px;
}

.radioButton > div[role="radio"]:not(.p-disabled):not(.p-highlight):hover {
  border: 4px solid #87B76C;
}

.radioButton > div[role="radio"] > div[class="p-radiobutton-icon"]{
  background-color: #fff;
}

.radioButton > div[role="radio"] {
  border: 2px solid #ced4da;
  background: #ffffff;
  width: 20px;
  height: 20px;
  color: #495057;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s,
    box-shadow 0.2s;
}

.dropdown{
  border: none !important;
  width: 100%;
}

.dropdown span{  
  padding-left: 0px;
}

.dropdown span[class="p-dropdown-label p-inputtext p-placeholder"]{
  font-weight: 700;
  color: #495057;
  font-family: MontserratRegular;
  font-style: normal;
  letter-spacing: -.1px;
  font-weight: normal;
  font-size: 12px;
  color: #aaa8a8;

}
.dropdown span[class="p-dropdown-label p-inputtext"]{
  font-weight: 700;
  color: #495057;
  font-family: MontserratRegular;
  border: none !important;
}
.dropdown span[class="p-dropdown-label p-inputtext p-placeholder"]{
  border: none !important;
}

.selectButton div[aria-pressed="true"] {
    background: #ffa307 !important;
    border-color: #ffa307 !important;
    color: #ffffff;
}

.inputWrapper textarea{
  width: 100%;
}