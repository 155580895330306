body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1, h2, h3, h4, h5, h6 {
  color: #343434;
}

:root {
  --font-family-bold: 'MontserratBold';
  --font-family-regular: 'MontserratRegular';
  --color-silver: #909090;
  --color-dark-shadow: #4C4C4C;
  --color-header-down-level:#909090;
  --background-def: #FDFCFC;
  --color-ground: #1e3050;
  --border-radius-custom: 10px;
  --box-shadow: 0 4px 4px #00000052;
  --color-green: #4CAF50;
  --color-next: #3B8F12;
  --color-red: #FF0202;
  --color-second: #FE5661;
}